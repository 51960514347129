<template>
  <NotFoundComponent />
</template>
<script>
import NotFoundComponent from '@/components/NotFoundComponent.vue'

export default {
  name: 'notfound',
  components: {
    NotFoundComponent
  }
}
</script>
